import RouteInfo from '@ember/routing/route-info';
import RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import WindowService from 'mobile-web/services/window';

import { AnalyticsProperties } from './analytics';
import BootstrapService from './bootstrap';
import FeaturesService from './features';
import DeviceService from './device';

type PathConfig = {
  cookieName: string;
  href: (to: RouteInfo) => string;
  toName: string;
};

const CONFIGS: PathConfig[] = [
  {
    cookieName: 'olo-serve-next-licenses',
    href: () => '/open-source-licenses',
    toName: 'open-source-licenses',
  },
  {
    cookieName: 'srvnxtty',
    href: to => `/thank-you/${to.params.order_id}`,
    toName: 'thank-you',
  },
  {
    cookieName: 'srvnxtlogin',
    href: () => '/login',
    toName: 'login',
  },
  {
    cookieName: 'srvnxtlocationsearch',
    href: () => '/search',
    toName: 'vendor-search-results',
  },
  {
    cookieName: 'srvnxtmenu',
    href: to => `/menu/${to.parent!.params.vendor_slug}`,
    toName: 'menu.vendor.index',
  },
  {
    cookieName: 'srvnxtmenu',
    href: to => `/menu/${to.params.vendor_slug}/products/${to.params.product_id}`,
    toName: 'menu.vendor.products',
  },
  {
    cookieName: 'srvnxtmenu',
    href: to => `/menu/${to.params.vendor_slug}/categories/${to.params.category_id}`,
    toName: 'menu.category',
  },
];

export default class MigrationService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service features!: FeaturesService;
  @service router!: RouterService;
  @service window!: WindowService;
  @service device!: DeviceService;

  // Untracked properties
  nextLocationIsTracked = false;
  // Tracked properties
  @tracked isRedirecting = false;

  // Getters and setters

  // Lifecycle methods
  constructor() {
    super(...arguments);

    this.router.on('routeWillChange', async transition => {
      const props = this.getPropsFor(transition.to?.name);
      if (props?.shouldTransition) {
        this.isRedirecting = true;

        const href = props.href(transition.to);
        this.window.location().assign(href);

        transition.abort();
      }
    });

    // This additional event handler is for when users land on the index page.
    // I found that the routeWillChange event handler above was not getting
    // instantiated until after routing in the app lifecycle, and thus it
    // would not fire in time to redirect users to the search page when landing
    // on the home page. I tried creating an instance-initializer, which worked
    // for the event handler, but then we did not yet have access to LD FF's,
    // so it was too early to know whether we wanted to redirect the user anyway.
    // Rather than messing with where we initialize LD FF data, I chose to add
    // this extra event handler, which will be called after both service instantiation
    // and FF instantiation in the app's life cycle.
    this.router.on('routeDidChange', async transition => {
      const props = this.getPropsFor('vendor-search-results');

      if (
        this.serveNextLocationSeachPageEnabled &&
        transition.to?.name === 'index' &&
        !this.skipHomePage
      ) {
        this.trackServeNextLocationAsAbFlagOnce();
      }

      if (props?.shouldTransition && transition.to?.name === 'index' && this.skipHomePage) {
        this.isRedirecting = true;

        const href = '/search';
        this.window.location().assign(href);

        transition.abort();
      }
    });
  }

  // Other methods
  trackServeNextLocationAsAbFlagOnce() {
    // skipHomePage users will be tracked when transition to /serach route completed and within serve-next project
    // that should account for when user already on /search route and refresh the page,
    // it will skip ember and will directly log event from serve-next
    if (!this.nextLocationIsTracked && this.features.analytics) {
      this.features.analytics.trackEvent(`LD abtest-serve-next-location-search`, () => ({
        [AnalyticsProperties.FlagValue]:
          this.features.flags['abtest-new-location-search-page-olo-93790'],
        [AnalyticsProperties.IsControl]: !document.cookie.includes(`srvnxtlocationsearch=1`),
        [AnalyticsProperties.FlagUserKey]: this.features.ldUserContext?.uniqueId,
      }));

      this.nextLocationIsTracked = true;
    }
  }

  // Tasks

  // Actions and helpers
  get skipHomePage() {
    const variation = this.features.flags['abtest-new-location-search-page-olo-93790'] as string;
    return variation === 'B';
  }

  getPropsFor(toName?: string) {
    const clientConfig = CONFIGS.find(c => c.toName === toName);
    if (!clientConfig) {
      return undefined;
    }

    const serveNextConfig = this.bootstrap.data?.serveNextConfig;
    const serverConfig = serveNextConfig?.pathConfigs.find(
      c => c.cookieName === clientConfig.cookieName
    );
    if (!serverConfig) {
      return undefined;
    }

    const isDisabled = serverConfig.flagValue === -1 || serveNextConfig!.isDisabled;

    return {
      href: clientConfig.href,
      shouldTransition:
        !isDisabled &&
        !this.device.isHybrid &&
        (serverConfig.flagValue === 100 ||
          document.cookie.includes(`${serverConfig.cookieName}=1`)),
    };
  }

  get serveNextLocationSeachPageEnabled() {
    const serveNextConfig = this.bootstrap.data?.serveNextConfig;
    const serverConfig = serveNextConfig?.pathConfigs.find(
      c => c.cookieName === 'srvnxtlocationsearch'
    );
    if (!serverConfig) {
      return undefined;
    }
    return !(serverConfig?.flagValue === -1 || serveNextConfig!.isDisabled);
  }
}
declare module '@ember/service' {
  interface Registry {
    migration: MigrationService;
  }
}
